<template>
  <a-form-model
      ref="formRef"
      class="staff-add-form"
      :model="formData"
      :rules="rules"
      :label-col="formLayout.labelCol"
      :wrapper-col="formLayout.wrapperCol"
  >
    <a-row>
      <a-col :span="24">
        <a-form-model-item
            :label="$t('hrManagement.staffList.form.name.label')"
            prop="name"
        >
          <a-input
              v-model="formData.name"
              :placeholder="$t('hrManagement.staffList.form.name.placeholder')"
          />
        </a-form-model-item>
      </a-col>
      <!--<a-col :span="24">
        <a-form-model-item
            :label="$t('hrManagement.staffList.form.userType.label')"
            prop="userType"
        >
          <a-select
              v-model="formData.userType"
              :placeholder="$t('hrManagement.staffList.form.userType.placeholder')"
              allowClear
          >
            <a-select-option
                v-for="op in userTypeList"
                :key="op.dictCode"
                :value="op.dictCode"
            >
              {{ op.dictName }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
      </a-col>-->
      <a-col :span="24">
        <a-row class="staff-form-item-mobile">
          <a-col :span="calcFormItemLayout([10, 14]).labelCol.span" class="staff-form-item-mobile-code">
            <a-form-model-item
                :label="$t('hrManagement.staffList.form.mobilePhoneNumber.label')"
                prop="mobilePhoneCode"
                :label-col="{ span: 12 }"
                :wrapper-col="{ span: 12 }"
            >
              <a-input
                  class="staff-form-item-mobile-code-input"
                  v-model="formData.mobilePhoneCode"
                  :placeholder="$t('hrManagement.staffList.form.mobilePhoneCode.placeholder')"
                  disabled
              />
            </a-form-model-item>
          </a-col>
          <a-col :span="calcFormItemLayout([10, 14]).wrapperCol.span" class="staff-form-item-mobile-number">
            <a-form-model-item
                label=""
                prop="mobilePhoneNumber"
                :label-col="{ span: 0 }"
                :wrapper-col="{ span: 24 }"
            >
              <a-input
                  v-model="formData.mobilePhoneNumber"
                  :placeholder="$t('hrManagement.staffList.form.mobilePhoneNumber.placeholder')"
              />
            </a-form-model-item>
          </a-col>
        </a-row>
      </a-col>
      <a-col :span="24">
        <a-form-model-item
            :label="$t('hrManagement.staffList.form.telephoneNumber.label')"
            prop="telephoneNumber"
        >
          <a-input
              v-model="formData.telephoneNumber"
              :placeholder="$t('hrManagement.staffList.form.telephoneNumber.placeholder')"
          />
        </a-form-model-item>
      </a-col>
      <a-col :span="24">
        <a-form-model-item
            :label="$t('hrManagement.staffList.form.emailAddress.label')"
            prop="emailAddress"
        >
          <a-input
              v-model="formData.emailAddress"
              :placeholder="$t('hrManagement.staffList.form.emailAddress.placeholder')"
          />
        </a-form-model-item>
      </a-col>
      <a-col :span="24">
        <a-form-model-item
            :label="$t('hrManagement.staffList.form.gender.label')"
            prop="gender"
        >
          <a-radio-group v-model="formData.gender">
            <a-radio :value="UserSex[0].dictCode">
              {{ UserSex[0].dictName }}
            </a-radio>
            <a-radio :value="UserSex[1].dictCode">
              {{ UserSex[1].dictName }}
            </a-radio>
          </a-radio-group>
        </a-form-model-item>
      </a-col>
      <a-col :span="24">
        <a-form-model-item
            :label="$t('hrManagement.staffList.form.birthday.label')"
            prop="birthday"
        >
          <a-date-picker
              v-model="formData.birthday"
              :placeholder="$t('hrManagement.staffList.form.birthday.placeholder')"
          />
        </a-form-model-item>
      </a-col>
      <a-col :span="24">
        <a-form-model-item
            :label="$t('hrManagement.staffList.form.induction.label')"
            prop="induction"
        >
          <a-date-picker
              v-model="formData.induction"
              :placeholder="$t('hrManagement.staffList.form.induction.placeholder')"
          />
        </a-form-model-item>
      </a-col>
      <!--<a-col :span="24">
        <a-form-model-item
            :label="$t('hrManagement.staffList.form.master.label')"
            prop="master"
        >
          <a-switch
              v-model="formData.master"
              :placeholder="$t('hrManagement.staffList.form.master.placeholder')"
          />
        </a-form-model-item>
      </a-col>-->
      <!--<a-col :span="24">
        <a-form-model-item
            :label="$t('hrManagement.staffList.form.roleIds.label')"
            prop="roleIds"
        >
          <a-select
              mode="multiple"
              v-model="formData.roleIds"
              :placeholder="$t('system.user.form.roleIds.placeholder')"
              allowClear
          >
            <a-select-option
                v-for="op in roleList"
                :key="op.roleId"
                :value="op.roleId"
            >
              {{ op.name }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
      </a-col>-->
      <a-col :span="24">
        <a-form-model-item
            :label="$t('hrManagement.staffList.form.headImg.label')"
            prop="headImg"
        >
          <ec-form-upload
              :format="['image/jpeg', 'image/jpg', 'image/png']"
              :fileList.sync="fileList"
              :number-max="1"
              :size-max="10737418.24"
              @change="onFileUploadChange"
          />
        </a-form-model-item>
      </a-col>
      <a-col :span="24">
        <a-form-model-item
          :label="$t('hrManagement.staffList.form.company.label')"
          prop="companyName"
        >
          <a-input
            v-model="formData.companyName"
            :placeholder="$t('hrManagement.staffList.form.company.placeholder')"
            disabled
          />
        </a-form-model-item>
      </a-col>
      <!--<a-col :span="24">
        <a-form-model-item
          :label="$t('hrManagement.staffList.form.company.activeStatus.label')"
          prop="activeStatus"
        >
          <a-select
            v-model="formData.activeStatus"
            :placeholder="$t('hrManagement.staffList.form.company.activeStatus.placeholder')"
            allowClear
          >
            <a-select-option
              v-for="op in WorkingStatus"
              :key="op.dictCode"
              :value="op.dictCode"
            >
              {{ op.dictName }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
      </a-col>-->
      <!--<a-col :span="24">
        <a-form-model-item
          :label="$t('hrManagement.staffList.form.company.isCurrent.label')"
          prop="isCurrent"
        >
          <a-switch
            v-model="formData.isCurrent"
            :placeholder="$t('hrManagement.staffList.form.company.isCurrent.placeholder')"
          />
        </a-form-model-item>
      </a-col>-->
      <!--<a-col :span="24">
        <a-form-model-item
          :label="$t('hrManagement.staffList.form.company.joinGrantPoints.label')"
          prop="joinGrantPoints"
          :label-col="{ span: 6 }"
          :wrapper-col="{ span: 18 }"
        >
          <a-switch
            v-model="formData.joinGrantPoints"
            :placeholder="$t('hrManagement.staffList.form.company.joinGrantPoints.placeholder')"
          />
        </a-form-model-item>
      </a-col>-->
      <a-col :span="24">
        <a-form-model-item
            :label="$t('hrManagement.staffList.form.deptId.label')"
            prop="deptId"
        >
          <a-select
              :getPopupContainer="trigger => trigger.parentNode"
              style="position: relative;"
              v-model="formData.deptId"
              :placeholder="$t('hrManagement.staffList.form.deptId.placeholder')"
              allowClear
          >
            <a-select-option
                v-for="op in deptList"
                :key="op.deptId"
                :value="op.deptId"
            >
              {{ op.deptName }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
      </a-col>
      <a-col :span="24" v-if="!isEdit">
        <a-form-model-item
            :label="$t('hrManagement.staffList.form.sendMsg.label')"
            prop="sendMsg"
            :label-col="calcFormItemLayout([10, 18]).labelCol"
            :wrapper-col="calcFormItemLayout([10, 18]).wrapperCol"
        >
          <a-switch v-model="formData.sendMsg" />
        </a-form-model-item>
      </a-col>
      <slot></slot>
    </a-row>
  </a-form-model>
</template>

<script>
import { DateFormat, FETCH_CODE, REG_EMAIL, REG_MOBILE } from "@/config";
import { cloneDeep } from "lodash";
import { UserSex, UserType, WorkingStatus } from "@/pages/system/config";
import { listOrg, listRole } from "@/pages/system/api";
import {showMsg} from "@/utils";
import EcFormUpload from "@/components/EcFormUpload.vue";
import moment from "moment";
import { langMixin } from "@/mixins";

export default {
  name: "UserEditForm",
  components: { EcFormUpload },
  props: {
    form: {
      type: Object,
    },
    isEdit: Boolean,
  },
  mixins: [langMixin],
  data() {
    return {
      rules: {
        name: [
          { required: true, message: this.$t('hrManagement.staffList.form.name.validation.required') },
          { max: 100, message: this.$t('hrManagement.staffList.form.name.validation.max') },
        ],
        // userType: [
        //   { required: true, message: this.$t('hrManagement.staffList.form.userType.validation.required') },
        // ],
        mobilePhoneCode: [
          { required: true, message: this.$t('hrManagement.staffList.form.mobilePhoneCode.validation.required') },
        ],
        mobilePhoneNumber: [
          { required: true, message: this.$t('hrManagement.staffList.form.mobilePhoneNumber.validation.required') },
          { pattern: REG_MOBILE, message: this.$t('hrManagement.staffList.form.mobilePhoneNumber.validation.mobile') },
        ],
        telephoneNumber: [
          { max: 20, message: this.$t('hrManagement.staffList.form.telephoneNumber.validation.max', { length: 20 }) },
        ],
        emailAddress: [
          { required: true, message: this.$t('hrManagement.staffList.form.emailAddress.validation.required') },
          { pattern: REG_EMAIL, message: this.$t('hrManagement.staffList.form.emailAddress.validation.email') },
          { max: 100, message: this.$t('hrManagement.staffList.form.emailAddress.validation.max') },
        ],
        gender: [],
        birthday: [],
        induction: [],
        master: [],
        roleIds: [],
        headImg: [],
        sendMsg: [],
        activeStatus: [],
        isCurrent: [],
        joinGrantPoints: [],
        deptId: [],
      },
      UserSex: cloneDeep(UserSex),
      roleList: [],
      userTypeList: cloneDeep(UserType),
      WorkingStatus: cloneDeep(WorkingStatus),
      fileList: [],
      deptList: [],
    };
  },
  computed: {
    formData: {
      get () { return this.form },
      set (values) {
        this.onChange({
          ...this.form,
          ...values,
        });
      },
    },
    formLayout() {
      return this.calcFormItemLayout([5, 7]);
    }
  },
  methods: {
    onChange(values) {
      this.$emit("update:form", {
        ...this.formData,
        ...values,
      });
    },
    async getRoleList() {
      this.roleList = [];
      const res = await listRole();
      if (res.flag !== FETCH_CODE.SUCCESS.KEY) {
        return;
      }
      this.roleList = res.data;
    },
    async getDeptList() {
      this.deptList = [];
      if (!this.form.companyId) return;
      const res = await listOrg({
        name: "",
        companyId: this.form.companyId,
      });
      this.deptList = res.data || [];
    },
    onFileUploadChange(fileList) {
      const file = fileList[0];
      if (!file || !file.response) return;
      const { data } = file.response;
      if (!data) return;
      this.onChange({ headImg: data });
    },
    onValid() {
      return new Promise((resolve) => {
        this.$refs.formRef.validate(async (valid, errors) => {
          if (!valid) {
            showMsg({flag: 1, msg: Object.values(errors)[0]?.[0]?.message});
            resolve(false);
            return;
          }
          const params = {
            ...this.formData,
            master: this.formData.master ? 1 : 0,
            birthday: this.formData.birthday ? moment(this.formData.birthday).format(DateFormat) : "",
            induction: this.formData.induction ? moment(this.formData.induction).format(DateFormat) : "",
            // userCompany: {
            //   companyId: this.formData.companyId,
            //   activeStatus: this.formData.activeStatus,
            //   joinGrantPoints: this.formData.joinGrantPoints,
            // },
          };
          resolve(params);
        });
      });
    },
    init(formData = this.formData) {
      if (formData.headImg) {
        this.fileList = [{
          uid: "-1",
          name: "headImg.png",
          status: "done",
          url: formData.headImg,
        }];
      }
      if (formData.companyId) {
        const { currentCompany } = this.$store.state.user.info;
        if (currentCompany.companyId === currentCompany.companyId) {
          this.form.companyName = currentCompany.name;
        }
        this.getDeptList();
      }
      if (!formData.deptId) {
        this.formData.deptId = undefined
      }
    },
  },
  mounted() {
    this.getRoleList();
    const { currentCompany } = this.$store.state.user.info;
    if (currentCompany.companyId) {
      this.form.companyId = currentCompany.companyId;
      this.form.companyName = currentCompany.name;
      this.getDeptList();
    }
  },
}
</script>

<style lang="less" scoped>
.staff-form-item-mobile {
  &-code {
  }
  &-code-input {
    text-align: right;
  }
  &-number {
  }
}
</style>
