<template>
  <a-modal
      :visible="visible"
      @cancel="onCancel"
      @ok="onConfirm"
      class="staff-add-modal"
      :maskClosable="false"
      :title="$t('hrManagement.staffList.addModal.title')"
  >
    <a-tabs class="staff-add-tabs" :activeKey="activeTab" type="card" @change="onTabs">
      <a-tab-pane key="1" :tab="$t('hrManagement.staffList.addModal.single.tab')">
        <StaffForm ref="staffFormRef" type="0" @close="onCancel" />
      </a-tab-pane>
      <a-tab-pane key="2" :tab="$t('hrManagement.staffList.addModal.multi.tab')">
        <StaffAddMulti ref="staffAddMultiRef" />
      </a-tab-pane>
    </a-tabs>
  </a-modal>
</template>

<script>
import StaffForm from "./StaffForm.vue";
import StaffAddMulti from "./StaffAddMulti.vue";

export default {
  name: "StaffAddModal",
  components: {
    StaffForm,
    StaffAddMulti,
  },
  props: {
    visible: Boolean,
  },
  data() {
    return {
      activeTab: "1",
    };
  },
  methods: {
    onCancel(options) {
      this.$refs.staffFormRef && this.$refs.staffFormRef.onReset();
      this.$refs.staffAddMultiRef && this.$refs.staffAddMultiRef.onReset();
      this.$emit("close", options);
    },
    onConfirm() {
      if (this.activeTab === "1") {
        this.$refs.staffFormRef.onSubmit();
        return;
      }
      // 批量导入点击确定直接关闭
      this.$refs.staffAddMultiRef.onReset();
      this.$emit("close");
    },
    onTabs(activeTab) {
      this.activeTab = activeTab;
    },
  },
}
</script>

<style scoped lang="less">

</style>
