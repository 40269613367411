<template>
  <a-modal
    :visible="visible"
    @cancel="onCancel"
    @ok="onConfirm"
    class="staff-edit-modal"
    :maskClosable="false"
    :title="$t('hrManagement.staffList.editModal.title')"
  >
    <StaffForm
      ref="staffFormRef"
      :userId="userId"
      type="1"
      @close="onCancel"
      v-if="visible"
    />
  </a-modal>
</template>

<script>
import StaffForm from "@/pages/HR/StaffForm.vue";

export default {
  name: "StaffEditModal",
  components: {
    StaffForm,
  },
  props: {
    visible: Boolean,
    userId: String,
  },
  data() {
    return {};
  },
  methods: {
    onCancel(options) {
      this.$refs.staffFormRef.onReset();
      this.$emit("close", options);
    },
    onConfirm() {
      this.$refs.staffFormRef.onSubmit();
    },
  },
};
</script>

<style scoped lang="less"></style>
