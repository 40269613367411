<template>
  <div class="hr-management">
     <a-tabs v-model="currentTab" @change="onTab">
        <a-tab-pane key="1" :tab="$t('hrManagement.staffList.tab')">
          <StaffList />
        </a-tab-pane>
        <a-tab-pane key="2" :tab="$t('hrManagement.orgList.tab')">
          <OrgList />
        </a-tab-pane>
    </a-tabs>
  </div>
</template>
<script>
import StaffList from "@/pages/HR/StaffList.vue";
import OrgList from "./Org.vue";

export default {
  name: "HrManagement",
  components: {
    StaffList,
    OrgList,
  },
  data () {
    return {
      currentTab: "1",
    };
  },
  methods: {
    onTab(e) {
      console.log(e);
    },
  },
}
</script>

<style scoped lang="less">
.hr-management {

}
</style>
