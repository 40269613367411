import { render, staticRenderFns } from "./StaffAddMulti.vue?vue&type=template&id=0f08b090&scoped=true"
import script from "./StaffAddMulti.vue?vue&type=script&lang=js"
export * from "./StaffAddMulti.vue?vue&type=script&lang=js"
import style0 from "./StaffAddMulti.vue?vue&type=style&index=0&id=0f08b090&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0f08b090",
  null
  
)

export default component.exports