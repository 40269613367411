<template>
  <div class="staff-add-multi">
    <div class="staff-add-multi-top">
      <span>{{ $t("hrManagement.staffList.addModal.multi.tips") }}</span>
      <a
        href="/template/StaffTemplate.xlsx"
        class="staff-add-multi-download-btn"
        download="新建员工模板-20230529214957.xlsx"
      >
        {{ $t("button.downloadS") }}
      </a>
    </div>
    <a-form-model
      ref="formRef"
      class="staff-add-multi-form"
      :model="form"
      v-bind="formItemLayout"
      :rules="rules"
    >
      <a-form-model-item
        :label="$t('hrManagement.staffList.form.company.label')"
        prop="companyName"
      >
        <a-input
          v-model="form.companyName"
          :placeholder="$t('hrManagement.staffList.form.company.placeholderFirst')"
          disabled
        />
      </a-form-model-item>
      <a-form-model-item :label="$t('file.upload')" prop="file">
        <div class="dropbox">
          <EcUpload
            :required="uploadRequired"
            :fileName="downloadFileName"
            formName="file"
            action="/api/company/staff/import"
            :fileList.sync="fileList"
            :params="{ companyId: form.companyId, sendMsg: form.sendMsg }"
            @change="onFileChange"
          />
        </div>
      </a-form-model-item>
      <a-form-item
        :label="$t('hrManagement.staffList.form.sendMsg.label')"
        prop="sendMsg"
        :label-col="calcFormItemLayout([11, 18], [12, 6]).labelCol"
        :wrapper-col="calcFormItemLayout([11, 18], [12, 6]).wrapperCol"
      >
        <a-switch v-model:checked="form.sendMsg" />
      </a-form-item>
    </a-form-model>
  </div>
</template>

<script>
import moment from "moment";
import EcUpload from "@/components/EcUpload.vue";
import { cloneDeep } from "lodash";
import { langMixin } from "@/mixins";

const emptyForm = {
  companyId: undefined,
  file: null,
  sendMsg: true
};

export default {
  name: "StaffAddMulti",
  components: {
    EcUpload
  },
  mixins: [langMixin],
  data() {
    return {
      form: {
        ...emptyForm,
        companyId: this.$store.state.user.info.currentCompany.companyId,
        companyName: this.$store.state.user.info.currentCompany.name,
      },
      fileList: [],
      loading: false,
      rules: {},
    };
  },
  computed: {
    downloadFileName() {
      let name = this.$t("hrManagement.staffList.addModal.multi.downloadFile");
      name += moment().format(".YYYYMMDDHHmmss");
      return name + ".xlsx";
    },
    uploadRequired() {
      return this.$t("hrManagement.addModal.multi.required");
    },
    formItemLayout() {
      return this.calcFormItemLayout([5, 8], [15, 14]);
    }
  },
  methods: {
    // 重置
    onReset() {
      this.$refs.formRef.resetFields();
      this.form = cloneDeep(emptyForm);
      this.fileList = [];
    },
    onFileChange() {}
  },
  mounted() {
    const { currentCompany } = this.$store.state.user.info;
    if (currentCompany.companyId) {
      this.form.companyId = currentCompany.companyId;
      this.form.companyName = currentCompany.name;
    }
  }
};
</script>

<style scoped lang="less">
.staff-add-multi {
  &-download-btn {
    padding: 0 20px;
    height: 30px;
    line-height: 28px;
    background: rgba(2, 122, 255, 0.08);
    border-radius: 30px 30px 30px 30px;
    border: 1px solid @primary-color;
    font-size: 14px;
    font-weight: 400;
    color: @primary-color;
    text-align: center;
  }
  &-top {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 14px;
    margin-top: 20px;
  }
  &-form {
    margin-top: 40px;
  }
}
</style>
