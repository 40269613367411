<template>
  <div class="ec-form-text">
    <div class="ec-form-text-prefix">
      <slot name="prefix"></slot>
    </div>
    <div class="ec-form-text-value">{{value}}</div>
    <div class="ec-form-text-suffix">
      <slot name="suffix"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "EcFormText",
  props: {
    value: {
      default: null,
    },
  },
  data() {
    return {};
  },
  methods: {},
};
</script>

<style scoped lang="less">
.ec-form-text {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: nowrap;
}
</style>
