<template>
  <div class="staff-list">
    <div class="staff-list-top">
      <a-button class="staff-list-add-btn" type="primary" @click="onAdd">
        {{ $t("hrManagement.staffList.addBtn") }}
      </a-button>
    </div>
    <ECTable
      title=""
      class="staff-list-table"
      :columns="columns"
      :data-source="data"
      :bordered="false"
      :showTitle="false"
      :loading="loading"
      :scroll="{ x: 800 }"
      :pagination="{ current, pageSize, total }"
      @change="onPageChange"
    >
      <template slot="operation" slot-scope="text, record">
        <div class="table-operations">
          <a-button
            class="staff-list-leave-btn"
            :class="record.activeStatus !== 1 ? 'disabled' : ''"
            type="link"
            @click="onLeave(record)"
            :disabled="record.activeStatus !== 1"
          >
            {{ $t("hrManagement.staffList.removeBtn") }}
          </a-button>
          <a-button
            class="staff-list-add-btn"
            type="link"
            @click="onEdit(record)"
          >
            {{ $t("hrManagement.staffList.editBtn") }}
          </a-button>
        </div>
      </template>
      <template slot="isDisabled" slot-scope="text, record">
        <a-switch readonly :checked="!record.isDisabled" />
      </template>
    </ECTable>
    <StaffAddModal :visible="addVisible" @close="onAddClose" />
    <StaffEditModal
      :visible="editVisible"
      :userId="editRecord.userId"
      @close="onEditClose"
    />
    <a-modal
      @ok="onLeaveOk"
      @cancel="onLeaveCancel"
      v-model="isLeaveModal"
      :title="$t('hrManagement.staffList.leaveModal.title')"
      class="leave-modal"
    >
      <div class="leave-modal_inner">
        <div>
          <span class="icon-tip icon-tips" />{{
            $t("hrManagement.staffList.leaveModal.tip")
          }}
        </div>
        <div>
          <span>{{
            $t("hrManagement.staffList.leaveModal.returnCoin", { name: platformConf.moneyName })
          }}： </span>
          <a-switch v-model="isReturn" />
        </div>
      </div>
    </a-modal>
  </div>
</template>

<script>
import ECTable from "@/components/ECTable";
import StaffAddModal from "./StaffAddModal.vue";
import StaffEditModal from "@/pages/HR/StaffEditModal.vue";
import { cloneDeep, trim } from "lodash";
import { FETCH_CODE } from "@/config";
import { showMsg } from "@/utils";
import { listDicFormatter, productItemPriceFormatter } from "@/utils/tools";
import { UserSex, UserType } from "@/pages/system/config";
import { leaveStaff, pageStaff } from "@/pages/HR/api";
import {mapState} from "vuex";

export default {
  name: "StaffList",
  components: {
    StaffEditModal,
    ECTable,
    StaffAddModal
  },
  data() {
    return {
      columns: [
        {
          title: this.$t("hrManagement.staffList.table.col1"),
          dataIndex: "name",
          key: "name",
          align: "left",
          width: 120,
          ellipsis: true
        },
        // {
        //   title: this.$t("hrManagement.staffList.table.col11"),
        //   dataIndex: "typeName",
        //   key: "typeName",
        //   align: "center",
        //   width: 100,
        //   ellipsis: true
        // },
        {
          title: this.$t("hrManagement.staffList.table.col2"),
          dataIndex: "mobile",
          key: "mobile",
          align: "left",
          width: 160,
          ellipsis: true
        },
        {
          title: this.$t("hrManagement.staffList.table.col3"),
          dataIndex: "emailAddress",
          key: "emailAddress",
          align: "left",
          width: 200,
          ellipsis: true
        },
        {
          title: this.$t("hrManagement.staffList.table.col4"),
          dataIndex: "deptName",
          key: "deptName",
          align: "left",
          width: 120,
          ellipsis: true
        },
        {
          title: this.$t("hrManagement.staffList.table.col5"),
          dataIndex: "statusName",
          key: "statusName",
          align: "center",
          width: 100,
          ellipsis: true
        },
        {
          title: this.$t("hrManagement.staffList.table.col6", {
            name: this.$store.state.user.platformConf.moneyName
          }),
          dataIndex: "balance",
          key: "balance",
          align: "right",
          width: 100,
          ellipsis: true
        },
        {
          title: this.$t("hrManagement.staffList.table.col7"),
          dataIndex: "loginNum",
          key: "loginNum",
          align: "right",
          width: 100,
          ellipsis: true
        },
        {
          title: this.$t("hrManagement.staffList.table.col8"),
          dataIndex: "lastTime",
          key: "lastTime",
          align: "center",
          width: 160,
          ellipsis: true
        },
        // {
        //   title: this.$t("hrManagement.staffList.table.col9"),
        //   dataIndex: "isDisabled",
        //   key: "isDisabled",
        //   align: "center",
        //   width: 100,
        //   scopedSlots: { customRender: "isDisabled" },
        //   ellipsis: true
        // },
        {
          title: this.$t("hrManagement.staffList.table.col10"),
          dataIndex: "operation",
          key: "operation",
          align: "center",
          width: 150,
          scopedSlots: { customRender: "operation" },
          fixed: "right"
        }
      ],
      data: [],
      loading: false,
      total: 0,
      current: 1,
      pageSize: 10,
      addVisible: false,
      editVisible: false,
      editRecord: {},
      isReturn: true,
      isLeaveModal: false,
      leaveRecord: {}
    };
  },
  methods: {
    // 新增员工
    onAdd() {
      this.addVisible = true;
    },
    onAddClose(options = {}) {
      this.addVisible = false;
      if (options.reload) {
        this.getList();
      }
    },
    // 编辑员工
    onEdit(record) {
      this.editRecord = cloneDeep(record);
      this.editVisible = true;
    },
    // 员工离职
    onLeave(item) {
      if (item.activeStatus !== 1) return;
      this.leaveRecord = item;
      this.isLeaveModal = true;
    },
    onLeaveCancel() {
      this.isReturn = true;
      this.isLeaveModal = false;
    },
    async onLeaveOk() {
      const res = await leaveStaff({
        userId: this.leaveRecord.userId,
        recoveryPoints: this.isReturn
      });
      if (res.flag !== FETCH_CODE.SUCCESS.KEY) {
        showMsg({ flag: FETCH_CODE.ERROR.KEY, msg: res.msg });
        return;
      }
      showMsg({
        flag: FETCH_CODE.SUCCESS.KEY,
        msg: this.$t("showMsg.success.operation")
      });
      this.isLeaveModal = false
      this.getList();
    },
    // 编辑关闭
    onEditClose(options = {}) {
      this.editVisible = false;
      this.editRecord = {};
      if (options.reload) {
        this.getList();
      }
    },
    onPageChange(page) {
      this.current = page.current;
      this.pageSize = page.pageSize;
      this.getList();
    },
    // 获取列表
    async getList() {
      this.loading = true;
      const params = {
        pageNum: this.current,
        pageSize: this.pageSize,
        userName: "",
        type: ""
      };
      const res = await pageStaff(params);
      if (res.flag !== FETCH_CODE.SUCCESS.KEY) {
        showMsg(res);
        this.loading = false;
        return;
      }
      const list = res.data.records || [];
      list.forEach(it => {
        it.userType = it.type;
        it.balance = productItemPriceFormatter(it.coinNum || 0);
        it.mobile = `${
          it.mobilePhoneCode ? "+" + it.mobilePhoneCode + " " : ""
        }${it.mobilePhoneNumber}`;
      });
      this.data = await listDicFormatter(list, [
        { code: UserSex, key: "gender", local: true },
        { code: UserType, key: "type", local: true }
      ]);
      this.current = res.data.current;
      this.total = res.data.total;
      this.pageSize = res.data.size;
      this.loading = false;
    }
  },
  computed: {
    ...mapState({
      platformConf: state => state.user.platformConf,
    }),
  },
  mounted() {
    this.getList();
  }
};
</script>

<style scoped lang="less">
.staff-list {
  &-top {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  &-table {
    margin-top: 22px;
    .staff-list-leave-btn {
      color: @error-color;

      &.disabled {
        color: @disabled-color;
      }
    }
  }
}

.leave-modal {
  &_inner {
    margin: 0 auto;
    font-size: 16px;
    width: 240px;
    & > * {
      margin-bottom: 20px;
    }
  }
  /deep/.icon-tips {
    display: inline-block;
    vertical-align: middle;
    margin-right: 4px;
  }
}
</style>
