<template>
  <div class="org-list">
    <div class="org-list-top">
      <a-button
        class="org-list-add-btn"
        type="primary"
        @click="onImport"
        disabled
      >
        {{ $t("hrManagement.orgList.addBtn") }}
      </a-button>
      <p class="org-list-tips">
        {{ $t("hrManagement.orgList.tips") }}
      </p>
    </div>
    <a-tree
        class="org-list-tree"
        :expanded-keys="expanded"
        :tree-data="orgTree"
        @expand="onExpand"
        :replaceFields="{ children: 'list', title: 'deptName', key: 'deptId' }"
    />
  </div>
</template>

<script>
import { treeOrg } from "@/pages/system/api";

export default {
  name: "OrgList",
  data() {
    return {
      orgTree: [
        {
          title: "A",
          key: "1",
          children: [
            { title: "A1", key: "11" },
            { title: "A2", key: "12" },
            { title: "A3", key: "13" },
            { title: "A4", key: "14" },
            { title: "A5", key: "15" },
          ],
        },
        {
          title: "B",
          key: "2",
        },
        {
          title: "C",
          key: "3",
        },
      ],
      expanded: [],
    };
  },
  methods: {
    // 导入
    onImport() {},
    // 节点展开事件
    onExpand(e) {
      this.expanded = e;
    },
    // 获取组织树
    async getTree() {
      const res = await treeOrg({
        companyId: this.$store.state.user.info.currentCompany.companyId,
      });
      this.orgTree = res.data;
    },
  },
  mounted() {
    this.getTree();
  },
}
</script>

<style scoped lang="less">
.org-list {
  &-top {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 15px;
  }
  &-tips {
    margin-bottom: 0;
  }
}
</style>
