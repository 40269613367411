<template>
  <div class="staff-add">
    <UserEditForm
        ref="userFormRef"
        :form.sync="form"
        :is-edit="type === '1'"
    >
      <a-col :span="24" v-if="type === '1'">
        <a-form-model-item
            :label="$t('hrManagement.staffList.form.balance.label', { name: platformConf.moneyName })"
        >
          <EcFormText :value="form.pointsShow">
            <div slot="suffix">
              <a-button type="link" @click="onReturnPoints">
                {{$t("hrManagement.staffList.form.balance.returnBtn", { name: platformConf.moneyName })}}
              </a-button>
            </div>
          </EcFormText>
        </a-form-model-item>
      </a-col>
    </UserEditForm>

    <!-- 回收福币modal -->
    <a-modal
      :visible="isReturnModal"
      @cancel="onReturnCancel"
      @ok="onReturnConfirm"
      :maskClosable="false"
      :title="$t('hrManagement.staffList.returnModal.title', { name: platformConf.moneyName })"
  >
      <a-form-model
        ref="returnFormRef"
        class="staff-return-points-form"
        :model="returnForm"
        :rules="returnFormRules"
        :label-col="formItemLayout.labelCol"
        :wrapper-col="formItemLayout.wrapperCol"
      >
        <a-row>
          <a-col :span="24">
            <a-form-model-item
              :label="$t('hrManagement.staffList.returnModal.name')"
              prop="name"
            >
              <a-input
                v-model="returnForm.name"
                :placeholder="$t('hrManagement.staffList.returnModal.name')"
                disabled
              />
            </a-form-model-item>
          </a-col>
          <a-col :span="24">
            <a-form-model-item
              :label="$t('hrManagement.staffList.returnModal.currency')"
              prop="currency"
            >
              <a-select
                :getPopupContainer="trigger => trigger.parentNode"
                style="position: relative;"
                v-model="returnForm.currency"
                :placeholder="$t('hrManagement.staffList.returnModal.currency')"
                disabled
              >
                <a-select-option
                  v-for="op in currencyList"
                  :key="op.dictCode"
                  :value="op.dictCode"
                >
                  {{ op.dictName }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
          <a-col :span="24">
            <a-form-model-item
              :label="$t('hrManagement.staffList.returnModal.balance', { name: platformConf.moneyName })"
              prop="balance"
            >
              <a-input
                v-model="returnForm.balance"
                disabled
              />
            </a-form-model-item>
          </a-col>
          <a-col :span="24">
            <a-form-model-item
              :label="$t('hrManagement.staffList.returnModal.points')"
              prop="points"
            >
              <a-input-number
                v-model="returnForm.points"
                :placeholder="$t('hrManagement.staffList.returnModal.points')"
                :min="0"
                :precision="2"
              />
            </a-form-model-item>
          </a-col>
          <a-col :span="24">
            <a-form-model-item
              :label="$t('hrManagement.staffList.returnModal.remark')"
              prop="remark"
            >
              <a-textarea
                v-model="returnForm.remark"
                :placeholder="$t('hrManagement.staffList.returnModal.remark')"
                :auto-size="{ minRows: 3, maxRows: 5 }"
              />
            </a-form-model-item>
          </a-col>
        </a-row>
      </a-form-model>
    </a-modal>
  </div>
</template>

<script>
import { FETCH_CODE, REG_EMAIL, REG_MOBILE } from "@/config";
import EcFormText from "@/components/EcFormText.vue";
import { cloneDeep } from "lodash";
import { showMsg } from "@/utils";
import { addStaff, infoStaff, updateStaff } from "@/pages/HR/api";
import { getDic } from "@/api";
import { recoveryPoints } from "@/pages/resource/api";
import UserEditForm from "@/pages/HR/UserEditForm.vue";
import { mapState } from "vuex";
import {pointsSaveFormatter, pointsShowFormatter} from "@/utils/tools";
import { langMixin } from "@/mixins";

const emptyForm = {
  name: null,
  userType: undefined,
  mobilePhoneCode: 86,
  mobilePhoneNumber: null,
  telephoneNumber: null,
  emailAddress: null,
  gender: null,
  birthday: null,
  induction: null,
  master: false,
  roleIds: undefined,
  headImg: null,
  companyId: undefined,
  deptId: undefined,
  activeStatus: undefined,
  isCurrent: false,
  joinGrantPoints: false,
  sendMsg: true,
};

const emptyReturnForm = {
  name: null,
  userId: null,
  currency: undefined,
  balance: null,
  points: null,
  remark: null,
};

export default {
  name: "StaffForm",
  components: {UserEditForm, EcFormText },
  props: {
    // 表单状态 0=新增 1=编辑
    type: {
      validator(value) {
        return ["0", "1"].includes(value);
      },
    },
    // 编辑用户id
    userId: String,
  },
  mixins: [langMixin],
  data() {
    return {
      loading: false,
      formLayout: "horizontal",
      form: cloneDeep(emptyForm),
      isReturnModal: false,
      returnLoading: false,
      returnForm: cloneDeep(emptyReturnForm),
      returnFormRules: {
        points: [
          { required: true, message: this.$t("hrManagement.staffList.returnModal.pointsRequired") },
        ],
        remark: [
          { max: 255, message: this.$t("hrManagement.staffList.returnModal.remarkMax", { length: 255 }) },
        ],
      },
      fileList: [],
      currencyList: [],
    };
  },
  computed: {
    ...mapState({
      platformConf: state => state.user.platformConf,
    }),
    formItemLayout() {
      return this.calcFormItemLayout([5, 7]);
    }
  },
  methods: {
    REG_EMAIL() {
      return REG_EMAIL
    },
    REG_MOBILE() {
      return REG_MOBILE
    },
    // 提交
    onSubmit() {
      if (this.loading) return;
      this.loading = true;
      this.$refs.userFormRef.onValid().then(async (params) => {
        if (!params) {
          this.loading = false;
          return;
        }
        let res;
        let msg = this.$t("showMsg.success.add");
        // 新增
        if (this.type === "0") {
          res = await addStaff(params);
        }
        // 修改
        else {
          res = await updateStaff(params);
          msg = this.$t("showMsg.success.change");
        }
        if (res.flag !== FETCH_CODE.SUCCESS.KEY) {
          showMsg(res);
          this.loading = false;
          return;
        }
        showMsg({
          flag: FETCH_CODE.SUCCESS.KEY,
          msg,
        });
        // 保存成功 关闭并刷新列表
        this.$emit("close", { reload: true });
        this.loading = false;
      });
    },
    // 重置
    onReset() {
      this.form = {
        ...this.form,
        ...emptyForm,
      };
    },
    // 回收福币
    async onReturnPoints() {
      this.returnLoading = true;
      this.isReturnModal = true;
      await this.getCurrencyList();
      this.returnForm = {
        ...this.returnForm,
        name: this.form.name,
        userId: this.form.userId,
        currency: "benefits_coin",
        balance: this.form.pointsShow,
      };
      this.returnLoading = false;
    },
    onReturnCancel () {
      this.returnForm = cloneDeep(emptyReturnForm);
      this.isReturnModal = false
    },
    onReturnConfirm () {
      if (this.returnLoading) return;
      this.returnLoading = true;
      this.$refs.returnFormRef.validate(async (valid, errors) => {
        if (!valid) {
          showMsg({ flag: 1, msg: Object.values(errors)[0]?.[0]?.message });
          this.returnLoading = false;
          return;
        }
        const params = {
          ...this.returnForm,
          points: pointsSaveFormatter(this.returnForm.points),
          userIds: [this.returnForm.userId],
        };
        const res = await recoveryPoints(params);
        if (res.flag !== FETCH_CODE.SUCCESS.KEY) {
          showMsg(res);
          this.returnLoading = false;
          return;
        }
        showMsg({
          flag: FETCH_CODE.SUCCESS.KEY,
          msg: this.$t("showMsg.success.operation"),
        });
        this.onReturnCancel();
        // 刷新详情
        this.getDetail();
        // 刷新余额
        this.$store.dispatch("user/getUserInfo");
        this.returnLoading = false;
      });
    },
    onFileUploadChange(fileList) {
      const file = fileList[0];
      if (!file || !file.response) return;
      const { data } = file.response;
      if (!data) return;
      this.form.headImg = data;
    },
    async getDetail() {
      this.loading = true;
      const res = await infoStaff({
        userId: this.userId,
      });
      if (res.flag !== FETCH_CODE.SUCCESS.KEY) {
        showMsg(res);
        this.loading = false;
        this.$emit("close", { reload: false });
        return;
      }
      this.form = {
        ...res.data,
        userType: res.data.type,
        pointsShow: pointsShowFormatter(res.data.points || 0),
      };
      this.$nextTick(() => {
        this.$refs.userFormRef.init();
      });
      this.loading = false;
    },
    async getCurrencyList() {
      const res = await getDic({
        code: "currency",
      });
      this.currencyList = res.data || [];
    },
  },
  mounted() {
    if (this.userId) {
      this.getDetail();
    }
  },
}
</script>

<style scoped lang="less">
.staff-add {
  &-form {
    .ant-calendar-picker {
      width: 100%;
    }
  }
  .staff-form-item-mobile {
    &-code {
    }
    &-code-input {
      text-align: right;
    }
    &-number {
    }
  }
}
.ec-radio {
  display: flex;
  align-items: center;
  // justify-content: center;
  &_wrap {
    display: flex;
  align-items: center;
  // justify-content: center;
  }
  &_item {
    width: 20px;
    height: 20px;
    background: #CCD8F0;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 6px;
    cursor: pointer;
    &-active {
      background: #2B88FF;
    }
  }
  &_label {
    margin-right: 20px;
  }
  &_check {
    color: #fff;
    font-size: 12px;
  }
}
</style>
